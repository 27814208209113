.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: min(100% - 4rem);
  margin-top: 10vh;
  color: var(--color1);
  z-index: 0;
  width: 100%;
}

.not-found-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.not-found-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.return-home-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: var(--color5);
  color: var(--color1);
  border-color: var(--color1);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.return-home-button:hover {
  cursor: pointer;
  opacity: 0.7;
  transform: translateY(1px);
  color: var(--color3);
}

@media only screen and (max-width: 900px) {
  .not-found-container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .not-found-message {
    width: 80%;
    font-size: 16px;
    text-align: center;
    margin: 8rem 0 4rem;
  }
}
