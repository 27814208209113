.projects-post {
  display: flex;
  justify-content: center;
  width: min(100% - 4rem);
  margin-inline: auto;
  height: 80svh;
  margin-top: 0;
  color: var(--color1);
  z-index: 0;
  position: relative;
}

.projects-post-container {
  margin-left: 0;
  margin-top: 10dvh;
  margin: 4em 0;
  padding: 20px;
  padding-bottom: 20dvh;
  line-height: 2.5em;
}

.date {
  display: flex;
  justify-content: flex-end;
  font-style: italic;
}

.projects-post-container > h1 {
  padding: 1.5em 0;
  font-size: 3em;
}

.project-navigation {
  display: flex;
  flex-direction: column;
  padding: 1em 0;
}

.project-navigation-links {
  padding: 1em 0;
  pointer-events: auto;
}

.project-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-navigation-links.left {
  text-align: right;
}

.project-navigation-links.right {
  text-align: left;
}

.project-navigation-links p {
  color: var(--color2);
  opacity: 0.8;
}

.project-navigation-links {
  text-decoration: none;
}

.project-navigation-links p:hover {
  transform: translateY(-1px);
  opacity: 1;
}

.project-navigation-links p:active {
  transform: translateY(-2px);
  transform: rotateY(0.02turn);
}

.blog-post-img {
  width:100%;
  height: auto;
  border-radius: 4px;
}

@media only screen and (max-width: 768px) {
  .projects-post {
    text-align: left;
    width: 100%;
    margin: 0;
  }
}
