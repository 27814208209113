.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1em;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}

.links {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.copyright {
  font-size: 12px;
  padding: 0.2em 0.5em;
  display: flex;
  justify-content: flex-end;
  opacity: 0.5;
  color: var(--color4);
  font-style: italic;
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-info a {
  text-decoration: none;
  color: inherit;
  pointer-events: auto;
}

.contact-info a:hover {
  opacity: 0.7;
  transform: translateY(1px);
}

.contact-info a:active {
  transform: translateY(-0px);
}

@media only screen and (max-width: 768px) {
  .contact-info {
    margin: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    width: fit-content;
    height: 5em;
  }
}
