.about {
  display: flex;
  justify-content: center;
  width: min(100% - 4rem);
  margin-inline: auto;
  height: 80svh;
  margin-top: 0;
  color: var(--color1);
  z-index: 0;
}

h2,
h1,
h3 {
  font-family: var(--main-font), Georgia, 'Times New Roman', Times, serif;
}

.h2-fa-icon {
  color: var(--color2);
}

.h2-fa-icon span {
  color: var(--color1);
}

.about-split-view {
  display: flex;
  flex-direction: row;
  margin-left: 0;
  margin-top: 10dvh;
  position: relative;
  z-index: 0;
}

.section-div.left {
  z-index: 0;
}

.about-lhs {
  width: 100%;
  left: 0px;
  transform: translateX(-33%);
}

.about-rhs {
  display: flex;
  position: fixed;
  height: 80dvh;
  align-items: center;
  width: 45%;
  margin-left: auto;
  transform: translateX(66%);
}

.section-div {
  margin: 4em 0;
  padding: 20px;
  margin-bottom: 20dvh;
}

.section-div > h1 {
  padding: 1.5em 0;
  font-size: 3em;
}

.section-div > h2 {
  padding: 1.5em 0;
  font-size: 2.5em;
}

.section-div.left h2 > span {
  margin-right: 1em;
}

.section-div.right {
  text-align: right;
}

.section-div.right h2 > span {
  margin-left: 1em;
}

.section-div > p {
  padding: 2em;
}

.resume-photo-div {
  opacity: 0.9;
  padding: 0;
}

.jbimage {
  border-radius: 20px;
  height: auto;
  min-width: 23dvw;
  margin: 5dvh;
  opacity: 0.8;
  box-shadow: 0px 1px 8px var(--color2);
  transition: all ease 0.6s;
}

.jbimage:hover {
  transform: translateY(5px);
  box-shadow: 0px 2px 16px 1px var(--color3);
}

.download-link {
  color: var(--color2);
}

.download-link-link {
  text-decoration: none;
  color: var(--color2);
  opacity: 0.8;
  z-index: 1000;
}

.download-link-link:hover {
  opacity: 1;
  transform: translateY(-1px);
}

.filler {
  height: 10dvh;
  display: flex;
  align-items: center;
}

.download {
  width: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 6em;
  color: var(--color2);
  animation: floating 3s ease-in-out infinite;
}

.download > p:hover {
  opacity: 1;
  transform: translateY(-30px);
  animation: floating 1s ease-in-out infinite;
}

.download > p:active {
  transform: translateY(-2px);
  transform: rotateY(0.05turn);
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(1px, 4px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.download > span {
  margin-left: 2em;
}

.current-skills-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 2em;
  margin-bottom: 10vh;
}

@media only screen and (max-width: 768px) {
  .jbimage {
    justify-content: center;
    margin-left: 0;
  }

  .download {
    margin-bottom: 0;
  }
  .section-div.right {
    text-align: left;
  }

  .section-div.left h2 > span {
    margin-right: 0em;
  }

  .section-div.left {
    text-align: left;
  }

  .section-div.right h2 > span {
    margin-left: 0em;
  }

  .about {
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 1em;
  }
}

@media only screen and (max-width: 1200px) {
  .jbimage {
    max-height: 75dvh;
    width: auto;
    justify-content: center;
    margin-left: 0;
  }

  .filler {
    margin-bottom: 4em;
  }

  .section-div {
    padding: 0;
    margin: 0;
    margin-bottom: 10dvh;
  }

  .section-div > p {
    padding: 0.5em;
  }

  .section-div > h1 {
    font-size: 3em;
  }

  .section-div > h2 {
    font-size: 2.5em;
  }

  .about {
    display: flex;
    flex-direction: row;
  }
  .about-split-view {
    display: flex;
    flex-direction: column;
  }
  .about-rhs {
    position: relative;
    width: 100%;
    transform: translateX(0);
    justify-content: center;
    margin-top: 1em;
  }

  .about-lhs {
    width: 100%;
    left: 0px;
    transform: translateX(0);
  }

  .resume-photo-div {
    margin-bottom: 12vh;
  }

  .download {
    margin-top: 6em;
  }

  .download > span {
    margin-left: 1em;
  }
}

@media only screen and (min-width: 2680px) {
  .resume-photo-div {
    display: flex;
  }
}
