.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: min(100% - 4rem);
  margin-top: 10vh;
  color: var(--color1);
  z-index: 0;
  width: 100%;
}

.contact > ul {
  list-style: none;
  line-height: 3em;
  font-size: 1.125em;
}

.contact > li {
  color: var(--color2);
  font-size: 1.125em;
}
.contact-list > li > span {
  color: var(--color2);
  font-size: 3em;
  padding: 0 0.5em;
}

.contact-list > li > a > span {
  color: var(--color2);
  font-size: 3em;
  padding: 0 0.5em;
  min-width: 2.1em;
}

.contact-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-list > li {
  display: flex;
  line-height: 4em;
}

.contact-list a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.contact-list a:hover {
  opacity: 0.7;
}

.contact-list a:active {
  transform: translateY(-1px);
}

@media only screen and (max-width: 368px) {
  .contact-list > li > a > span {
    color: var(--color2);
    font-size: 2em;
    padding: 0 0.5em;
    min-width: 1em;
  }
}
