.container {
  position: relative;
  height: 90vh; /* Ensure the container takes the full viewport height */
  overflow: hidden; /* Hide any overflow */
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: min(100% - 4rem, 80%);
  margin-inline: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.home.hide {
  opacity: 0;
  visibility: hidden;
}

.home.show {
  opacity: 1;
  visibility: visible;
}

.welcome-div > p {
  color: var(--color1);
}

.welcome-div a {
  text-decoration: none;
  color: var(--color2);
  pointer-events: auto;
}

.welcome-text a:hover {
  color: var(--color1);
}

.welcome-div a:active {
  transform: translateY(-1px);
}

.welcome-text.welcome-text-span:hover {
  color: var(--color1);
}

.welcome-text .clear-span {
  animation: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: initial;
  background: none;
  color: var(--color1); /* Ensuring it uses the default text color */
  background-size: auto;
}

.welcome-div {
  width: fit-content;
  color: var(--color2);
}

.welcome-div span {
  background: linear-gradient(
    135deg,
    var(--color1) 20%,
    var(--color2) 70%,
    var(--color3) 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 4s ease-in-out infinite alternate;
}

.welcome-div h1 {
  background: linear-gradient(225deg, var(--color1) 0%, var(--color2) 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 10s ease-in-out infinite alternate;
  filter: drop-shadow(1px 1px 128px var(--color1));
  letter-spacing: 1px;
}

.scroll-div {
  width: fit-content;
  color: var(--color2);
}

.scroll-div h1 {
  color: var(--color1);
}

.scroll-div p {
  color: var(--color3);
}

.feedback-container {
  width: fit-content;
  max-width: 80ch;
  display: flex;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  margin-top: 8rem;
  transition: opacity 1.5s 0s ease-in-out; /* Transition for fade-in effect */
}

.feedback-container.show-feedback {
  opacity: 1; /* Fade in when class is added */
}

.feedback-content {
  max-width: 66vw;
  width: 100%;
  color: var(--color1);
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: 0.01px;
  text-align: left;
  box-sizing: border-box;
  position: relative; /* Needed for the quotation mark styling */
  padding: 1rem; /* Add padding for better readability */
}

.feedback-content p {
  position: relative;
  margin: 0;
  padding: 0 1rem;
}

.feedback-content p::before,
.feedback-content p::after {
  content: '"';
  font-size: 3rem;
  position: absolute;
  color: var(--color1);
}

.feedback-content p::before {
  top: -1rem;
  left: -2rem;
}

.feedback-content p::after {
  bottom: -1rem;
  right: -2rem;
}

.feedback-provider {
  margin-top: 1em;
  width: fit-content;
  color: var(--color1);
  font-style: italic;
  text-align: left;
  font-weight: 200;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.1px;
  margin-left: auto;
  position: relative;
}

.feedback-provider::before {
  content: '~ ';
  position: absolute;
  left: -1.5rem;
}

.feedback-lower-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.final-message {
  padding-top: 10rem;
  height: 80dvh;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
}

.final-message.visible {
  opacity: 1;
  pointer-events: auto;
}

.final-message-content span {
  color: var(--color1);
}

.final-message-content ul {
  list-style: none;
  padding: 0;
}

.final-message-content li {
  margin: 1rem 0;
}

.final-message-content a {
  color: var(--color2);
  text-decoration: none;
}

.final-message-content a:hover {
  opacity: 0.7;
  transform: translateY(2px);
}

.welcome-text-scroll,
.welcome-text-scroll > p {
  display: flex;
  align-items: center;
  position: absolute; /* Position the element absolutely */
  bottom: 80px; /* Adjust the bottom position as needed */
  width: 100%; /* Ensure it takes the full width of the parent */
  color: var(--color2);
  animation: floating 3s ease-in-out infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 900px) {
  .home {
    text-align: center;
  }

  .welcome-div {
    line-height: 2em;
    text-align: center;
  }

  .feedback-content {
    padding: 0;
    margin-bottom: 4em;
  }

  .feedback-content > p {
    font-weight: 200;
    font-style: normal;
  }

  .feedback-container {
    margin-top: 6rem;
  }

  .welcome-text-scroll,
  .welcome-text-scroll > p {
    display: flex;
    justify-content: center;
    bottom: 180px;
    font-size: 14px;
    letter-spacing: 0.01px;
  }

  .lottie-icon {
    display: inline-flex;
    margin: -2em;
  }

  .back-button span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    margin-top: 2rem;
  }
}

.lottie-icon {
  display: inline-flex;
  margin: -0.5em;
}

.back-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.back-button {
  position: absolute;
  margin-right: auto;
  width: 66dvw;
  margin-right: auto;
  font-size: 4rem;
  color: var(--color1);
  animation: floating 3s ease-in-out infinite;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  height: 3rem;
}

.back-button span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
}

.back-button span:hover {
  cursor: pointer;
  opacity: 0.7;
  transform: translateY(1px);
}

.back-button span:active {
  transform: translateY(-0px);
}
