.header {
  background: linear-gradient(to bottom, var(--color5), 60%, transparent);
  height: 12dvh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 4em;
  height: 1dvh;
}

.navbar {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
}

.navbar {
  margin-left: 0;
}

.dark-mode-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  pointer-events: auto;
}

.navbar-container {
  min-width: 10em;
  width: fit-content;
}

.navbar-link {
  align-items: center;
  text-decoration: none;
  color: var(--color1);
  padding: 1em;
  opacity: 0.9;
  pointer-events: auto;
}

.navbar li:hover {
  opacity: 1;
  transform: translateY(-1px);
}

.navbar li:active {
  transform: translateY(-2px);
  transform: rotateY(0.05turn);
}

.homedirectory {
  padding: 1em;
  width: fit-content;
  pointer-events: auto;
}

.dark-mode-toggle {
  font-size: 1.5em;
  padding: 1em;
  color: var(--color1);
  transition: 0.2s ease;
}

.contact-info {
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.5em;
  width: fit-content;
  height: 4em;
}

.dark-mode-toggle:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

.where {
  background: linear-gradient(
    to right,
    var(--color1) 20%,
    var(--color2) 70%,
    var(--color3) 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 10s ease-in-out infinite alternate;
  font-style: italic;

}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeInAnimation;
  animation-duration: 1s;
}

@media only screen and (max-width: 800px) {
  .header {
    height: 25dvh;
  }

  .homedirectory {
    line-height: 1em;
  }

  nav {
    display: flex;
    flex-direction: row;
    height: 10dvh;
  }

  .navbar {
    display: flex;
    list-style-type: none;
    flex-direction: row;
    height: 10vh;
    align-items: center;
  }

  .navbar {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .navbar-link {
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 0.5em;
    opacity: 0.8;
  }

  .dark-mode-toggle {
    font-size: 1.5em;
    padding: 0.25em;
    color: var(--color1);
    transition: 0.2s ease;
    padding-top: 2em;
    justify-content: center;
  }

  .burger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 30px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    margin-top: 2em;
    margin-right: 1.5em;
  }

  .burger-menu span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--color1);
    transition: transform 0.3s ease;
  }

  .burger-menu span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .burger-menu span:nth-child(2) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .burger-menu span:nth-child(3) {
    top: 36px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .burger-menu.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }

  .burger-menu.active span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  .burger-menu.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
  }

  .mobile-menu {
    background-color: var(--color5);
    padding: 1em;
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 22dvh;
    left: 0;
    width: 100%;
    height: 100dvh;
  }

  .mobile-navbar {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .mobile-navbar li {
    margin-bottom: 1em;
  }

  .mobile-navbar-link {
    text-decoration: none;
    color: var(--color1);
  }
}
