@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@100;400&family=Inter:wght@400;600;700&family=Roboto:wght@400;700&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,800;1,400;1,800&display=swap');

@keyframes bg-animation {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

/*root colors and fonts*/
:root {
  --color5: rgb(24, 24, 24);
  --color4: #a1a6b4;
  --color3: #94c5cc;
  --color2: #b4d2e7;
  --color1: #fffefc;
  --main-font: 'Archivo Black', sans-serif;
  --secondary-font: 'Work Sans', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
}

h2,
h1,
h3 {
  font-family: var(--main-font), Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 0.0125em;
}

html {
  height: 100%;
}

main {
  width: min(65ch, 100% - 4rem);
  margin-inline: auto;
  font-family: var(--secondary-font), Georgia, 'Times New Roman', Times, serif;
  line-height: 1.75em;
  letter-spacing: 0.01em;
}

body {
  font-family: 'Work Sans', 'Inter', system-ui;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  /*background-image: linear-gradient(to bottom, #c8edf0, #2c3a3b);*/
  background-color: var(--color5);
  color: var(--color2);
  /*background: linear-gradient(-45deg, var(--color5), var(--color4));
  animation: gradient 15s ease-in-out infinite;*/
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

img,
svg {
  max-width: 100%;
  display: block;
}

.white-content {
  --color5: #fffefc;
  --color4: #b4d2e7;
  --color3: #94c5cc;
  --color2: #94c5cc;
  --color1: #272727;
}
