.projects {
  display: flex;
  justify-content: center;
  width: min(100% - 4rem);
  margin-inline: auto;
  margin-top: 0;
  color: var(--color1);
  z-index: 0;
  position: relative;
}

.projects-map {
  margin: 0;
  display: flex;
  align-items: center;
  flex: auto;
  margin: auto;
  margin-bottom: 10dvh;
  padding: 2em 1em;
  width: 40em;
}

.projects-link > img {
  transition: transform 200ms, opacity 500ms; /* Adds transition to both transform and opacity */
}

.projects-link > img:hover,
.projects-link h2:hover {
  opacity: 0.7;
}

.projects-link h2:hover {
  transform: translateY(-1px);
}

.projects-link > img:hover {
  transform: scale(1.01);
}

.projects-link > h2 {
  text-decoration: none;
  color: var(--color1);
}

.projects-map > .projects-description {
  width: 100%;
  margin: 1em;
  margin-bottom: 0em;
  opacity: 0.9;
}

.projects-section-div {
  margin: 4em 0;
  padding: 20px;
  margin-top: 20dvh;
}

.projects-img {
  border-radius: 8px;
  max-width: 360px;
  height: auto;
  margin: 2em 0em;
  border: 1px solid var(--color3);
  padding: 4px;
}

.projects-split-view > h1 {
  padding: 1.5em 0;
  font-size: 3em;
}

.projects-split-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  margin-top: 10vh;
  width: 100%;
  position: relative;
}

.projects-list {
  width: 100%;
  left: 0px;
}

@media only screen and (max-width: 768px) {
  .projects-map {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0em;
    margin-bottom: 10em 0;
  }

  .projects-split-view > p {
    text-align: center;
    pointer-events: auto;
  }

  .projects-map > .projects-description {
    width: 100%;
    margin: 0;
    margin-bottom: 4em;
  }

  .projects-img {
    max-width: 100%;
    height: auto;
    margin: 2em 0em;
  }
}
